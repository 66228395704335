import { useWidgetStore } from '@/components/features/Widget/useWidgetStore';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import { OnRamp, TransakEnvironment } from '@onbeam/onramp';
import { useDisclosure } from '@onbeam/utils';
import { PlusIcon } from '@sphere/icons';
import { Modal } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { toast } from 'sonner';

export const OnRampWidget = () => {
  const { t } = useTranslation('common');
  const marketplaceChain = useMarketplaceChain();
  const { userAddress } = useAuthentication();
  const { isOpen, onClose, onOpen } = useDisclosure();

  useWidgetStore.subscribe(state => state.widgetType === 'ONRAMP' && onOpen());

  return (
    <>
      <Flex flexDir="column" align="center" gap="space.6">
        <IconButton
          variant="secondary"
          onClick={onOpen}
          aria-label={t('widgets.onramp.label')}
          icon={<PlusIcon boxSize={rem(18)} />}
          boxSize={rem(42)}
        />
        <Text fontSize="xs">{t('widgets.onramp.label')}</Text>
      </Flex>
      <Modal
        testId="onramp-widget"
        isOpen={isOpen}
        onClose={onClose}
        maxW={rem(550)}
        h={rem(650)}
        p={0}
        bgColor="transparent"
        showCloseButton={false}
        hideBackground
      >
        <OnRamp
          style={{ height: rem(650) }}
          config={{
            network: 'beam',
            environment: marketplaceChain.testnet
              ? TransakEnvironment.STAGING
              : TransakEnvironment.PRODUCTION,
            apiKey: marketplaceChain.testnet
              ? '7c17f743-d186-475b-bf4f-23e781c56f09'
              : '192a6046-3a8f-48b7-937b-103e358ee527',
            walletAddress: userAddress,
            disableWalletAddressForm: true,
            cryptoCurrencyCode: 'BEAM',
            defaultCryptoAmount: 500,
          }}
          onOrderSuccess={() => toast.success(t('widgets.onramp.success'))}
          onOrderFailed={() => toast.error(t('widgets.onramp.error'))}
          withCard
        />
      </Modal>
    </>
  );
};
