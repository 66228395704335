import { Divider, Flex } from '@chakra-ui/react';
import { OnRampWidget } from './OnRampWidget';

export const WidgetButtons = () => {
  return (
    <>
      <Divider />
      <Flex w="full" justify="space-between" gap="space.8">
        <OnRampWidget />
      </Flex>
    </>
  );
};
