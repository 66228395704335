import {
  Modal as ChakraModal,
  IconButton,
  ModalBody,
  ModalContent,
  ModalContentProps,
  ModalOverlay,
} from '@chakra-ui/react';
import CloseNormalIcon from '@sphere/icons/components/CloseNormal';
import { rem } from 'polished';
import { UIEvent } from 'react';
import { useMeasure, useWindowSize } from 'react-use';
import { useUITranslation } from '../../context/TranslationProvider';
import { scrollStyles } from '../ScrollContainer/ScrollContainer';
import { ModalProps as ChakraModalProps, processModalProps as processProps } from './Modal.props';

type ModalProps = ChakraModalProps &
  ModalContentProps & {
    testId: string;
    onLoadMore?: () => void;
    closable?: boolean;
    showCloseButton?: boolean;
    hideBackground?: boolean;
  };

export const Modal = ({
  testId,
  onLoadMore,
  closable = true,
  showCloseButton = true,
  hideBackground = false,
  ...props
}: ModalProps) => {
  const { t } = useUITranslation();
  const { children, modalProps, ModalContentProps } = processProps(props);

  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const { height: windowHeight } = useWindowSize();
  const isCentered = windowHeight > height;

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const isAtBottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <=
      e.currentTarget.clientHeight * 1.5;
    isAtBottom && onLoadMore?.();
  };

  return (
    <ChakraModal
      motionPreset="slideInBottom"
      isCentered={isCentered}
      {...modalProps}
      {...(!closable && { closeOnEsc: false, closeOnOverlayClick: false })}
    >
      <ModalOverlay
        background="rgba(34, 34, 34, 0.4)"
        backdropFilter="auto"
        backdropBlur={rem(4)}
        data-testid={`${testId}-modal-overlay`}
      />
      <ModalContent
        ref={ref}
        data-testid={`${testId}-modal-content`}
        bgColor="blackBg"
        // Required for compatibility with Reservoir UI kit
        pointerEvents="auto"
        {...(onLoadMore && { onScroll: handleScroll })}
        {...ModalContentProps}
        css={scrollStyles}
        {...(hideBackground && { bgColor: 'transparent', boxShadow: 'none' })}
      >
        {closable && showCloseButton && (
          <IconButton
            data-testid={`${testId}-modal-close`}
            onClick={modalProps.onClose}
            variant="icon"
            zIndex={1}
            width={rem(12)}
            height={rem(12)}
            icon={<CloseNormalIcon />}
            color="inherit"
            aria-label={t('common.close')}
            position="absolute"
            top={{ base: 'xl', md: '2xl' }}
            right={{ base: 'xl', md: '2xl' }}
            _hover={{ opacity: 0.5 }}
          />
        )}
        <ModalBody p={0} data-testid={`${testId}-modal-body`}>
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
