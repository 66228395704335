import { env } from '@/env.mjs';
import supportedChains, { type ReservoirChain } from '@/utils/chains';
import { chainPaymentTokensMap } from '@/utils/paymentTokens';
import { ReservoirKitProviderProps } from '@sphere/reservoir-kit-ui';
import { Connector } from 'wagmi';
import { beamClient, beamProviderInfo } from '../beam/client';
import { darkTheme } from './theme';

const baseUrl = env.NEXT_PUBLIC_HOST_URL;
const source = env.NEXT_PUBLIC_MARKETPLACE_SOURCE;
type Config = ReservoirKitProviderProps['options'];

/**
 * Reservoir Kit Configuration
 *
 * @see https://docs.reservoir.tools/docs/reservoirkit-ui#configuring-reservoirkit-ui
 */
const reservoirKitConfig = (marketplaceChain: ReservoirChain): Config => ({
  apiKey: marketplaceChain.reservoirApiKey,
  chains: supportedChains.map(({ reservoirBaseUrl, proxyApi, id, name, checkPollingInterval }) => {
    return {
      id,
      name,
      baseApiUrl: proxyApi ? `${baseUrl}${proxyApi}` : reservoirBaseUrl,
      active: marketplaceChain.id === id,
      checkPollingInterval: checkPollingInterval,
      paymentTokens: chainPaymentTokensMap[id],
    };
  }),
  logLevel: 4,
  source,
  normalizeRoyalties: env.NEXT_PUBLIC_NORMALIZE_ROYALTIES,
  marketplaceFees: [
    `${env.NEXT_PUBLIC_MARKETPLACE_FEE_RECIPIENT}:${env.NEXT_PUBLIC_MARKETPLACE_FEE}`,
  ],
  disablePoweredByReservoir: true,
  coinGecko: {
    proxy: `${env.NEXT_PUBLIC_HOST_URL}/api/coin-gecko`,
  },
  onBeforeTokenAction: (action: string, connector?: Connector) => {
    if (connector?.id !== beamProviderInfo.rdns) return;

    if (['listToken', 'buyToken', 'placeBid', 'acceptOffer', 'cancelOrder'].includes(action)) {
      beamClient.openPopup();
    }
  },
});

const reservoirKitTheme = darkTheme;

export { reservoirKitConfig, reservoirKitTheme };
