import { IMXCheckoutActions } from '@/components/features/IMX/IMXCheckoutActions/IMXCheckoutActions';
import { WidgetButtons } from '@/components/features/Widget/WidgetButtons';
import { UserIconLink } from '@/components/features/layout/Header/Menu/UserMenu/UserIconLink';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import { isImmutableChain } from '@/utils/immutable';
import { routes } from '@/utils/routes';
import { Divider, Flex, Stack, useBreakpointValue } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { formatAddress } from '@onbeam/utils';
import { UserRole } from '@prisma/client';
import useTranslation from 'next-translate/useTranslation';
import { MenuLink } from '../../Components/MenuLink';
import { useMenu } from '../../useHeaderSettings/useMenu';
import WalletBalance from './WalletBalance';

type Props = {
  activeLinkColor?: string;
};

// We have to use a styled-component in order to use fallback css as Chakra does not support this
const FullDeviceFlex = styled(Flex)`
  height: 100vh;
  height: 100dvh;
`;

export const UserMenu = ({ activeLinkColor }: Props) => {
  const { t } = useTranslation('common');
  const { disconnect } = useAuthentication();
  const { user, userAddress: address } = useAuthentication();
  const marketplaceChain = useMarketplaceChain();
  const { setMenu } = useMenu();

  const handleDisconnect = () => {
    disconnect();
    setMenu(undefined);
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <FullDeviceFlex flexDir="column" w="full" h={{ md: 'auto' }} justify="space-between">
      <Stack
        direction="column"
        alignItems={{ md: 'flex-start' }}
        width={{ base: '100%', md: 'auto' }}
        spacing={{ base: 'space.16' }}
      >
        {address && isMobile && (
          <MenuLink fontSize="md" activeLinkColor={activeLinkColor} disabled>
            {t('header.nav.account.title')}
          </MenuLink>
        )}

        <MenuLink
          href={routes.profile(address ?? '', marketplaceChain.routePrefix)}
          fontSize="sm"
          activeLinkColor={activeLinkColor}
          data-testid="your-nfts-link"
        >
          {t('header.nav.account.items')}
        </MenuLink>

        {(user?.role === UserRole.CREATOR || user?.role === UserRole.ADMIN) && (
          <>
            <MenuLink
              href={routes.profile(user?.address ?? '', marketplaceChain.routePrefix, {
                tab: 'games',
              })}
              fontSize="sm"
              activeLinkColor={activeLinkColor}
            >
              {t('header.nav.account.games')}
            </MenuLink>
            <MenuLink
              href={routes.profile(user?.address ?? '', marketplaceChain.routePrefix, {
                tab: 'collections',
              })}
              fontSize="sm"
              activeLinkColor={activeLinkColor}
            >
              {t('header.nav.account.collections')}
            </MenuLink>
          </>
        )}

        <MenuLink
          href={routes.profile(user?.address ?? '', marketplaceChain.routePrefix, {
            tab: 'listings',
          })}
          fontSize="sm"
          activeLinkColor={activeLinkColor}
        >
          {t('header.nav.account.listings')}
        </MenuLink>

        <MenuLink
          href={routes.profile(user?.address ?? '', marketplaceChain.routePrefix, {
            tab: 'offers',
          })}
          fontSize="sm"
          activeLinkColor={activeLinkColor}
        >
          {t('header.nav.account.offers')}
        </MenuLink>

        <MenuLink
          href={routes.profile(user?.address ?? '', marketplaceChain.routePrefix, {
            tab: 'activity',
          })}
          fontSize="sm"
          activeLinkColor={activeLinkColor}
        >
          {t('header.nav.account.activity')}
        </MenuLink>
      </Stack>
      <Flex flexDir="column" w="full" pb={{ base: 'space.16', md: 'unset' }}>
        <Divider color="gray.1" />
        {address && (
          <UserIconLink
            displayName={user?.name || formatAddress(address)}
            activeLinkColor={activeLinkColor}
            href={routes.profile(user?.address ?? '', marketplaceChain.routePrefix)}
          />
        )}

        <WalletBalance />

        {isImmutableChain(marketplaceChain.id) ? <IMXCheckoutActions /> : <WidgetButtons />}

        <Divider color="gray.1" />

        <MenuLink
          onClick={() => handleDisconnect()}
          color="gray.6"
          activeLinkColor={activeLinkColor}
          useActiveClassName={false}
          fontSize="xs"
          hoverLinkColor="gray.4"
          w="fit-content"
        >
          {t('header.disconnect')}
        </MenuLink>
      </Flex>
    </FullDeviceFlex>
  );
};
