const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'development';
const SENTRY_TRACE_SAMPLE_RATE = Number(process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE || '0.5');

export default {
  dsn: SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: SENTRY_ENVIRONMENT,

  // Ignore the following errors
  ignoreErrors: [
    // Immutable: Ignore specific RuntimeError errors
    /^RuntimeError: Aborted\(CompileError: WebAssembly\.instantiate\(\)/,

    // Immutable: Ignore errors related to promise rejections with keys
    /Object captured as promise rejection with keys/,

    // Immutable: Ignore destructuring errors
    /Cannot destructure property 'address' of '\(intermediate value\)' as it is undefined/,
  ],
};
