import { create } from 'zustand';

type WidgetType = 'ONRAMP';

type Store = {
  widgetType?: WidgetType;
  setWidgetType(type?: WidgetType): void;
};

export const useWidgetStore = create<Store>(set => ({
  widgetType: undefined,
  setWidgetType: type => set({ widgetType: type }),
}));
