import { WalletIcon } from '@/components/shared/WalletIcon';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useAuthentication } from '@/hooks/useAuthentication';
import { beamClient } from '@/lib/beam/client';
import { SUPPORTED_CONNECTOR_IDS } from '@/utils/chains';
import { routes } from '@/utils/routes';
import { Box, Button, Flex, Grid, Heading, Image, Link, Text } from '@chakra-ui/react';
import { zIndices } from '@sphere/ui';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { useCallback, useEffect, useState } from 'react';
import { useDisconnect, type Connector } from 'wagmi';
import { WalletListItem } from './WalletListItem';

// type LinkData = {
//   url: string;
//   title: string;
//   subtitle?: string;
// };

export const ConnectWalletModalContent = () => {
  const { t } = useTranslation('common');
  const marketplaceChain = useMarketplaceChain();
  const { connect, connectors, pendingConnector, activeConnector, verify, isAuthenticating } =
    useAuthentication();
  const { disconnect } = useDisconnect();

  const [filteredConnectors, setFilteredConnectors] = useState<Connector[]>([]);

  useEffect(() => {
    setFilteredConnectors(
      connectors
        .filter(({ id }) => SUPPORTED_CONNECTOR_IDS.includes(id))
        .sort((a, b) => {
          const c = SUPPORTED_CONNECTOR_IDS.findIndex(id => id === a.id);
          const d = SUPPORTED_CONNECTOR_IDS.findIndex(id => id === b.id);
          return c - d;
        }),
    );
  }, [connectors, marketplaceChain]);

  const handleConnect = useCallback(
    (connector: Connector) => {
      // If the user is already connected to the connector, we want to log them out instead.
      if (activeConnector?.id === connector.id) {
        disconnect();
        return;
      }

      // Preload beamClient popup to prevent popup from being blocked by browsers like Safari
      if (connector.id === 'com.onbeam') {
        beamClient.openPopup();
      }

      connect({ connector, chainId: marketplaceChain.id });

      // When we are using the WalletConnect connector, we want to change its z-index CSS variable to
      // make sure we show it on top of the modal thats already open (on default its z-index is much lower).
      // We add a small timeout to make sure we override the property set by the w3m modal when it opens.
      connector.name === 'WalletConnect' &&
        setTimeout(
          () =>
            document.documentElement.style.setProperty(
              '--wcm-z-index',
              String(zIndices['wcm-modal']),
            ),
          500,
        );
    },
    [activeConnector, connect, disconnect, marketplaceChain],
  );

  const handleVerify = useCallback(async () => {
    if (!activeConnector) return;

    if (activeConnector.id === 'com.onbeam') {
      beamClient.openPopup();
    }

    const [account] = await activeConnector.getAccounts();
    await verify(account, activeConnector.id);
  }, [activeConnector, verify]);

  // const linkData = t<LinkData[] | string>(
  //   'zendesk.connect-wallet-articles',
  //   {},
  //   { returnObjects: true },
  // );

  // const [highlightedLink] = Array.isArray(linkData) ? linkData : [];

  return (
    <Box p={{ base: 'space.24', md: 'space.32' }}>
      <Heading size="lg" mb="space.16">
        {t('connect-wallet-modal.title')}
      </Heading>
      <Box bg="gray.2" p="space.16" rounded="radius.8">
        {activeConnector ? (
          <Flex
            direction="column"
            align="center"
            textAlign="center"
            py="space.20"
            mx="auto"
            gap="space.12"
            maxW={{ md: rem(320) }}
          >
            {activeConnector.icon ? (
              <Image
                src={activeConnector.icon}
                alt={activeConnector.name}
                w="full"
                maxW={{ base: rem(100), md: rem(38) }}
                maxH={{ md: rem(38) }}
              />
            ) : (
              <WalletIcon
                aria-hidden={true}
                connectorId={activeConnector.id}
                w="full"
                maxW={{ base: rem(100), md: rem(38) }}
                maxH={{ md: rem(38) }}
              />
            )}

            <Heading size="md"> {t('connect-wallet-modal.verify.title')}</Heading>
            <Text color="muted">{t('connect-wallet-modal.verify.body')}</Text>

            <Button onClick={() => handleVerify()} isLoading={isAuthenticating} mt="space.16">
              {t('connect-wallet-modal.verify.button-text')}
            </Button>

            <Button variant="link" size="sm" color="muted" onClick={() => disconnect()}>
              {t('cancel')}
            </Button>
          </Flex>
        ) : (
          <Grid templateColumns="repeat(1, 6fr)" gap="space.12">
            {filteredConnectors.map(connector => {
              const isUnsupportedConnector = !marketplaceChain.supportedConnectors.includes(
                connector.id,
              );

              return (
                <Box
                  key={connector.name}
                  rounded="radius.8"
                  p={{ base: 'space.32', md: 'space.16' }}
                  bg="blackBg"
                  boxShadow="0px 4px 16px rgba(0, 0, 0, 0.08)"
                >
                  <WalletListItem
                    connector={connector}
                    onClick={() => handleConnect(connector)}
                    isDisabled={isAuthenticating || isUnsupportedConnector}
                    tooltip={
                      isUnsupportedConnector
                        ? t('connect-wallet-modal.connect.button-disabled-tooltip', {
                            chain: marketplaceChain.name,
                            connector: connector.name,
                          })
                        : undefined
                    }
                    isLoading={pendingConnector?.id === connector.id && isAuthenticating}
                  >
                    {t('connect-wallet-modal.connect.button-text')}
                  </WalletListItem>
                </Box>
              );
            })}
          </Grid>
        )}
      </Box>
      <Text fontSize="sm" color="gray.6" mt="space.20">
        <Trans
          i18nKey="common:connect-wallet-modal.footer-text"
          components={[
            <Link
              key="terms-and-conditions"
              fontWeight="bold"
              color="white"
              href={routes.article.termsAndConditions}
              target="_blank"
            />,
            <Link
              key="privacy-policy"
              fontWeight="bold"
              color="white"
              href={routes.article.privacyPolicy}
              target="_blank"
            />,
          ]}
        />
      </Text>
      {/* TODO(support): uncomment this when the support pages are back online */}
      {/* {highlightedLink && (
        <>
          <Heading size="sm" mt={{ base: 'space.24', md: 'space.40' }} mb="space.12">
            {t('connect-wallet-modal.guides')}
          </Heading>
          <HighlightedLink
            text={highlightedLink.title}
            subtext={highlightedLink.subtitle}
            href={highlightedLink.url}
            target="_blank"
            rel="noopener noreferrer"
          />
        </>
      )} */}
    </Box>
  );
};
