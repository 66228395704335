import { SocialMediaLinksList } from '@/components/shared/SocialMediaLinks';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { routes } from '@/utils/routes';
import {
  Box,
  Container,
  GridItem,
  HStack,
  Link,
  SimpleGrid,
  Text,
  VisuallyHidden,
  chakra,
  useTheme,
  type StackProps,
} from '@chakra-ui/react';
import { useCookieConsentStore } from '@onbeam/utils';
import { LogoBetaIcon, LogoCompactIcon } from '@sphere/icons';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { FooterBottomLinks } from './components/FooterBottomLinks';
import { FooterLinkItem } from './components/FooterLinkItem';
import { FooterLinksList } from './components/FooterLinksList';
import { useFooterBottomLinksTranslation } from './useFooterBottomLinksTranslation';
import { useFooterLinksListTranslation } from './useFooterLinksListTranslation';

export function Footer(stackProps: StackProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const { translatedFooterLinks } = useFooterLinksListTranslation();
  const { translatedBottomFooterLinks } = useFooterBottomLinksTranslation();
  const { scrollY } = useScroll();
  const marketplaceChain = useMarketplaceChain();
  const openCookies = useCookieConsentStore(store => store.openConsentModal);

  useMotionValueEvent(
    scrollY,
    'change',
    scrollPos =>
      (document.documentElement.style.background = scrollPos > 500 ? theme.colors.black : ''),
  );

  return (
    <HStack
      as="footer"
      bg="black"
      mt={rem(-32)}
      overflow="hidden"
      pt="space.80"
      pb="space.64"
      position="relative"
      justify={{ base: 'flex-start', md: 'center' }}
      {...stackProps}
    >
      <Box zIndex="hidden" position="absolute" w="100%" h="100%">
        <Box
          position="absolute"
          w="100%"
          h="100%"
          bgGradient={{
            base: 'linear(135deg, var(--chakra-colors-black) 0%, var(--chakra-colors-black) 34%, rgba(19, 19, 19, 0.82) 100%)',
            md: 'linear(150deg, var(--chakra-colors-black) 0%, var(--chakra-colors-black) 44%, rgba(19, 19, 19, 0.85) 100%)',
            lg: 'linear(160deg, var(--chakra-colors-black) 0%, var(--chakra-colors-black) 52%, rgba(19, 19, 19, 0.86) 100%)',
          }}
        ></Box>
        <LogoCompactIcon
          aria-hidden="true"
          position="absolute"
          top={{ base: '0%', sm: '-30%', md: 'calc(-15vw)', lg: 'calc(20% - 35vw)' }}
          left={{ base: rem(-300), sm: rem(-250), md: '26%', lg: '30%' }}
          zIndex="hidden"
          opacity={0.3}
          w={{
            base: rem(900),
            sm: rem(1200),
            md: `calc(${rem(200)} + 80vw)`,
            lg: `calc(${rem(100)} + 80vw)`,
          }}
          h="auto"
          transform="rotate(155deg)"
        />
      </Box>
      <Container variant="breakout" px={{ base: 'space.28', md: 'space.40', lg: 'space.80' }}>
        <SimpleGrid
          columns={{ base: 2, md: 12 }}
          templateRows={{ md: `auto auto auto` }}
          gridAutoFlow="dense row"
          rowGap={{ base: 'space.48', md: 'space.20' }}
          columnGap={{ base: 'space.24' }}
          color="gray.5"
        >
          <GridItem
            display="flex"
            flexDir="column"
            colSpan={{ base: 2, md: 3 }}
            color="white"
            gap="space.16"
          >
            <Link
              href={routes.home(marketplaceChain.routePrefix)}
              /**
               * In order to make the footer visible to keyboard-only users, we ensure that the page scrolls to the bottom when the first element is focused.
               * This is necessary as the footer is currently located behind the main content of the page, and would not be visible otherwise.
               */
              onFocus={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                })
              }
            >
              <VisuallyHidden>{t('footer.home')}</VisuallyHidden>
              <LogoBetaIcon cursor="pointer" w={rem(115)} h="auto" aria-hidden="true" />
            </Link>
            <Text textStyle="footer-intro">
              <Trans i18nKey="common:footer.intro" components={{ b: <chakra.b color="white" /> }} />
            </Text>
            <Text color="gray.6">
              <Trans
                i18nKey="common:footer.coin-gecko"
                components={{
                  a: <Link color="white" fontWeight="bold" href={t('footer.coin-gecko-url')} />,
                }}
              />
            </Text>
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 8 }} colStart={{ md: 5 }} rowSpan={{ md: 3 }}>
            <SimpleGrid
              gridTemplateColumns={{ base: 'repeat(2,1fr)', md: 'repeat(3,1fr)' }}
              rowGap={'space.32'}
              columnGap={{ base: 'space.16', md: 'space.24' }}
            >
              {translatedFooterLinks.map(footerLinksList => (
                <GridItem key={footerLinksList.title}>
                  <FooterLinksList title={footerLinksList.title} links={footerLinksList.links} />
                </GridItem>
              ))}
            </SimpleGrid>
          </GridItem>
          <GridItem
            colSpan={{ md: 3 }}
            rowStart={{ md: 2 }}
            colStart={{ base: 2, md: 1 }}
            alignSelf={{ base: 'end', md: undefined }}
            display="flex"
            justifyContent={{ base: 'end', md: 'start' }}
          >
            <SocialMediaLinksList variant="footer" />
          </GridItem>
          <GridItem gridColumn={{ base: 'auto', md: '1 / 13' }}>
            <FooterBottomLinks links={translatedBottomFooterLinks}>
              <FooterLinkItem onClick={openCookies} text={t('footer.cookies')} />
            </FooterBottomLinks>
          </GridItem>
        </SimpleGrid>
      </Container>
    </HStack>
  );
}
